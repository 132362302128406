import { useEffect } from "react"
import "./Contact.css"

function Contact() {
    useEffect(() => {
        document.title = "Contact"
    }, [])
    return (
        <div>
            <img className="contactImg" src="./contact.jpg" alt="contact" />
        </div>
    )
}

export default Contact
