import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import { Link } from "react-router-dom"
import "./Navigation.css"
import { useState } from "react"

function Navigation() {
    const [expanded, setExpanded] = useState(false);
    const [active, setActive] = useState("home");

    return (
        <Navbar collapseOnSelect fixed="top" expand="sm" expanded={expanded} bg="white" className="mb-3">
            <Container>
                <Navbar.Brand as={Link} to="/" onClick={() => {
                    setActive("home")
                }}>Katharina Siemeling</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav
                        activeKey={active}
                        onSelect={(selectedKey) => setActive(selectedKey)}
                    >
                        <Nav.Link eventKey="projects" as={Link} to="/projects" onClick={() => setExpanded(false)}>Projects</Nav.Link>
                        <Nav.Link eventKey="cv" as={Link} to="/CV" onClick={() => setExpanded(false)}>CV</Nav.Link>
                        <Nav.Link eventKey="contact" as={Link} to="/contact" onClick={() => setExpanded(false)}>Contact</Nav.Link>
                        <Nav.Link eventKey="impressum" as={Link} to="/impressum" onClick={() => setExpanded(false)}>Impressum</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigation