import { useEffect } from "react"
import "./CV.css"

function CV() {
    useEffect(() => {
        document.title = "CV"
    }, [])
    return (
        <div>
            <h2>CV</h2>
            <p>*1992 Steinheim (NRW)</p>
            <table>
                <tbody>
                    <tr>
                        <td>2011-2022</td>
                        <td>Studium Freie Kunst, Kunstakademie Münster (Diplom)</td>
                    </tr>
                    <tr>
                        <td>2021</td>
                        <td>Meisterschülerin Prof. Aernout Mik</td>
                    </tr>
                    <tr>
                        <th></th>
                        <th>grants/scholarships</th>
                    </tr>
                    <tr>
                        <td>2017</td>
                        <td>Montepulciano Interdisziplinär, Kolleg für Musik und Kunst, Italien</td>
                    </tr>
                    <tr>
                        <td>2021</td>
                        <td>Reisestipendium der Kunstakademie Münster (São Tomé)</td>
                    </tr>
                    <tr>
                        <td>2022</td>
                        <td>NEUSTART KULTUR, Stiftung Kunstfonds</td>
                    </tr>
                    <tr>
                        <th></th>
                        <th>memberships</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Filmwerkstatt Münster e.V.</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>filmcollective AVA</td>
                    </tr>
                    <tr>
                        <th></th>
                        <th>Selected Exhibitions</th>
                    </tr>
                    <tr>
                        <td>2021</td>
                        <td>Open House, Speicher II, Münster</td>
                    </tr>
                    <tr>
                        <td>2019</td>
                        <td>The Public Matters, LWL Museum für Kunst und Kultur Münster</td>
                    </tr>
                    <tr>
                        <td>2018</td>
                        <td>Wrecklinghausen, Kunstakademie Münster, Pumpenhaus Münster</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>wolke, Eispalast Münster</td>
                    </tr>
                    <tr>
                        <td>2016</td>
                        <td>TOP, kunstraum 4, Horstmar</td>
                    </tr>
                    <tr>
                        <td>2015</td>
                        <td>youngstars, Wissenschaftspark Gelsenkirchen</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>DRINKING A DRINK, SINKING A SHIP, LIAN Contemporary Art Space, Shanghai</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>KOMM, Kunst Im Tunnel, Düsseldorf</td>
                    </tr>
                    <tr>
                        <td>2014</td>
                        <td>Video Folkwang - Die nächste Generation III, Museum Folkwang Essen</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>HAPPY HOUR, Galerie Januar Bochum, Weltkunstzimmer Düsseldorf, V Art Center Shanghai</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default CV
